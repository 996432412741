.section {
  margin-top: var(--size-lg);

  & > form {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.button {
  display: block;
  aspect-ratio: 1;
  margin-left: var(--size-md);
  border-radius: 50%;
  background-color: var(--color-contrast);
}

.button:hover {
  cursor: pointer;
  background: color-mix(
    in srgb,
    var(--color-contrast) 85%,
    var(--color-background)
  );
}

.prompt {
  margin: 0;
  padding: 0;
  list-style: none;
}

.instruction {
  width: 100%;
  min-height: 30px;
  padding: var(--size-base) var(--size-md);
  border: none;
  border-radius: var(--size-base);
  box-shadow: lightgrey 0 0 2px 4px;
}
