.blocks {
  margin: 0;
  padding: 0;
  list-style: none;
}

.blocks > li:not(:last-of-type) {
  margin-block-end: 30px;
}

.blocks ol {
  padding: 0;
  list-style: none;
}

.blocks p {
  margin: 0;
  margin-block-end: 0.8em;
  font-size: 0.8em;
}

.option {
  --background-mix: 70%;
  display: flex;
  align-items: baseline;
  border-radius: var(--size-base);
  box-shadow: var(--color-base) 1px 2px 5px 2px;
  border: var(--color-base) solid 1.5px;
  padding: var(--size-base) var(--size-md);
  font-weight: 200;

  background: color-mix(
    in srgb,
    var(--color-base) var(--background-mix),
    var(--color-background)
  );

  animation: fadeIn 1s;

  & input[type="radio"] {
    margin-right: var(--size-md);
  }

  & textarea {
    width: 100%;
    border: 0;
    padding: var(--size-base) var(--size-md);
  }
}

.option:hover,
.option:has(:focus) {
  --background-mix: 85%;
  cursor: pointer;
}

.option:has(:checked) {
  --background-mix: 100%;
  font-weight: normal;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}

li:has(.option):not(:last-of-type) {
  margin-block-end: var(--size-md);
}
