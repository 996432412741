.main {
  display: grid;
  grid-template-areas:
    "response"
    "editor"
    "storyboard";
  margin: 0 var(--size-lg);
}

.main section:nth-of-type(1) {
  grid-area: response;
}

.main section:nth-of-type(2) {
  grid-area: editor;
}

.main section:nth-of-type(3) {
  grid-area: storyboard;
}

@media (min-width: 500px) {
  .main {
    min-width: calc(500px - var(--size-md));
    width: 80%;
    margin: 0 auto;
  }
}

@media (min-width: 800px) {
  .main {
    grid: min-content 1fr / 3fr 2fr;
    column-gap: var(--size-lg);
    grid-template-areas:
      "editor response"
      "storyboard response";
  }
}

@media (min-width: 1000px) {
  .main {
    width: 800px;
  }
}
